.dashboard {
  &__card {
    border: 1px solid #cecece;
    font-family: "Inter", sans-serif !important ;

    &-title {
      font-size: 13px;
      font-weight: 400;
      //   line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #a399aa;
    }
    &-value {
      font-size: 20px;
      font-weight: 400;
      //   line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }

    &-icon {
      border: 1px solid #4b2727;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    &-percentage {
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0em;
      text-align: left;
    }

    &-date {
      color: #a399aa;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
