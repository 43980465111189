.main-footer {
  // font-family: 'Inter', sans-serif;
  &__b2b {
    // font-size: 64px;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    font-family: "Syncopate", sans-serif !important;
  }

  &__heading {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
  }

  &__sub-heading {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
  }

  &__link {
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  @media (max-width: 767.99px) {
    &__b2b {
      // font-size: 45px;
      font-size: 25px;
    }
  }
}
