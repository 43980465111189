.product {
  &__title {
    // font-size: 40px;
    font-size: 35px;
    font-weight: 500;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__bread-crum {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #868686;
    font-family: "Inter", sans-serif !important ;
  }

  &-details {
    font-family: "Inter", sans-serif !important ;

    &__image-large {
      border: 1px solid #8c8c8c80;
      height: 478px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__image-small {
      border: 1px solid #cecece;
      height: 145px;
      width: 123px !important;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
      }
    }

    &__sub-heading {
      font-size: 13px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #9fa9b9;
      font-family: "Inter", sans-serif !important ;
    }
    &__heading {
      // font-size: 29px;
      font-size: 25px;
      font-weight: 700;
      line-height: 35px;
      letter-spacing: 0em;
      text-align: left;
      // font-family: "Inter", sans-serif !important ;
      font-family: "Syncopate", sans-serif !important;
    }

    &__description {
      // font-size: 13px;
      font-size: 11px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      font-family: "Syncopate", sans-serif !important;
    }

    &__label {
      color: #7a7a7a;
      font-size: 13px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      font-family: "Inter", sans-serif !important ;
    }
    &__value {
      font-size: 13px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      font-family: "Inter", sans-serif !important ;
    }
    &__color {
      width: 33px;
      height: 40px;
      border-radius: 5px;
      object-fit: cover;
      transition: border 0.3s ease;
    }
    &__color:hover {
      // padding: 0.5px;
      border: 2px solid #b5b5b5;
      cursor: pointer;
    }
    &__selected-card {
      width: 33px;
      height: 40px;
      border-radius: 5px;
      border: 2px solid #16d18f;
    }

    &__btn {
      font-size: 14px;
    }

    @media (max-width: 767.99px) {
      &__image-large {
        height: 420px;
      }

      &__image-small {
        height: 140px;
        width: 118px !important;
      }

      &__sub-heading {
        font-size: 12px;
      }
      &__heading {
        // font-size: 25px;
        font-size: 20px;
        line-height: 30px;
      }

      &__description {
        // font-size: 12px;
        font-size: 10px;
      }

      &__label {
        font-size: 12px;
      }
      &__value {
        font-size: 12px;
      }
    }
  }
  &__card {
    transition: ease-in-out 300ms;
    .card-title {
      font-family: "Syncopate", sans-serif !important;
    }
  }
  &__card:hover {
    transform: scale(1.01);
  }
  a {
    color: transparent !important;
  }
}

.product-details__btn {
  &:hover,
  &:focus,
  &:active {
    background: #d8953e !important;
    box-shadow: none !important;
  }
}

///
.color-container {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  img {
    margin-bottom: 18px !important;
  }

  .color-name {
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease;
    position: absolute;
    margin-bottom: 13px;
    bottom: -12px;
    // cursor: pointer;
  }

  &:hover {
    .color-name {
      opacity: 1;
    }
  }
}
.product-details__list {
  padding: 0px 0px 0px 12px !important;
}
