.checkout {
  font-family: "Inter", sans-serif !important ;

  .StripeElement--empty {
    margin-top: 5px !important ;
  }

  .StripeElement {
    padding-top: 10px !important;
  }

  &__total {
    font-size: 20px;
  }
  &__title {
    font-size: 45px;
    font-weight: 700;
  }
  &__label {
    font-size: 14px !important;
    font-weight: 400 !important;

    letter-spacing: 0em !important;
    text-align: left !important;
  }

  &__payment {
    border: 1px solid #8c8c8c80;
  }

  .form-control {
    box-shadow: 0px 1px 2px 0px #151d2808;
    border: 1px solid #dee4ed;
    border-radius: 6px;
    color: #000000;
    font-family: "Inter", sans-serif;

    &:focus {
      outline: none !important;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0em;
      text-align: left;
      color: #788ba5;
    }
  }

  &__btn {
    font-size: 14px;
    font-weight: 600;
    background: #d8953e;
    border-radius: 12px !important;
    &:hover,
    &:focus,
    &:active {
      background: #d8953e !important;
      box-shadow: none !important;
    }
  }

  @media (max-width: 767.99px) {
    &__title {
      font-size: 35px;
    }
  }
  @media (max-width: 575.99px) {
    &__title {
      font-size: 28px;
    }
  }
}

//Stepper

.stepper {
  font-family: "Inter", sans-serif !important ;

  background: white;
  //   box-shadow: 0px 2px 4px 0px #151d280a;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #8c8c8c80;
  border-radius: 6px;
  &__icon {
    width: 50px;
    height: 50px;
    background: #dee2e6;
    color: black;
    font-size: 18px;
    font-weight: 600;
    border-radius: 50%;
  }
  &__label {
    font-size: 16px;
    font-weight: 500;
  }
  &__active {
    background: #d8953e !important;
  }

  @media (max-width: 767.99px) {
    &__icon {
      width: 35px;
      height: 35px;

      font-size: 16px;
    }
    &__label {
      font-size: 14px;
    }
  }

  @media (max-width: 399.99px) {
    &__icon {
      width: 28px;
      height: 28px;

      font-size: 15px;
    }
    &__label {
      font-size: 12px;
    }
  }
}
