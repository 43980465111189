$theme-colors: (
  // "site-primary": #efb6b6,
  "site-primary": #d8953e,
  "site-primary-dark": #d8953e,

  "site-secondary": #656464,
  "site-dark": #0a093d,
  "dark": #000000,
  "primary": #117df9,
  "success": #16d18f,
  "site-grey": #7a7777,
  "site-lite-grey": #e7e7e7
);

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syncopate:wght@400;700&display=swap");

@import "../../../node_modules/bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Anek+Tamil:wght@300;400;500;600;700;800&display=swap");

@import "./header.scss";
@import "./footer.scss";
@import "./auth.scss";
@import "./home.scss";
@import "./dashboard.scss";
@import "./search.scss";
@import "./product-details.scss";
@import "./checkout.scss";
@import "./order-tracking.scss";

body {
  // background: #efb6b6;
  background: #ffffff;

  font-family: "Montserrat", sans-serif;
}
.StripeElement--empty {
  margin-top: 5px !important ;
}
.form-control {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
.promo-btn {
  margin-bottom: 1rem !important;
}

.promo-applied {
  text-decoration: line-through !important;
}

.StripeElement {
  padding-top: 10px !important;
}

@for $i from 100 through 900 {
  .fw--#{$i} {
    font-weight: $i;
  }
}
@for $size from 5 through 96 {
  .fs--#{$size} {
    font-size: $size/16 + rem;
  }
}
.cursor-pointer {
  cursor: pointer;
}

//Custom btn

.custom {
  &__btn {
    font-family: "Inter", sans-serif;
    width: fit-content;

    box-shadow: 0px 2px 4px 0px #00000040;
    background: #ffffff;
    border-radius: 12px;
    border: none !important;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    &:hover,
    &:focus,
    &:active {
      background: #ffffff !important;
      box-shadow: 0px 2px 4px 0px #00000040;
    }
  }

  @media (max-width: 1199.99px) {
    &__btn {
      font-size: 22px;
    }
  }

  @media (max-width: 767.99px) {
    &__btn {
      font-size: 18px;
    }
  }

  @media (max-width: 567.99px) {
    &__btn {
      font-size: 16px;
    }
  }
}

//Page title{
.page-title {
  font-size: 64px;
  font-weight: 700;
  // line-height: 78px;
  letter-spacing: 0em;

  @media (max-width: 991.99px) {
    font-size: 50px;
  }
  @media (max-width: 767.99px) {
    font-size: 40px;
  }
  @media (max-width: 575.99px) {
    font-size: 30px;
  }
}

//custom-table

.custom-table {
  font-family: "Inter", sans-serif;

  border: 1px solid #cecece !important;

  thead {
    border: none !important;
    tr,
    th {
      border: none !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #757575;
      padding: 1rem 1.5rem;
    }
  }
  td {
    border-top: 1px solid #cecece !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 1rem 1.5rem;
    // min-width: 250px;
    min-width: 200px;
  }

  &__img {
    width: 51px;
    height: 57px;
    border: 1px solid #cecece80;
    position: relative;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__btn {
    font-family: "Anek Tamil", sans-serif;
    border-radius: 6px;
    box-shadow: 0px 0px 4px 0px #00000040;
    border: none !important;
    background: #d8953e;
    &:hover,
    &:focus,
    &:active {
      background: #d8953e !important;
      box-shadow: 0px 0px 4px 0px #00000040;
    }
  }
  &__avatar {
    min-width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: cover;
    max-width: 65px;
  }

  @media (max-width: 767.99px) {
    &__avatar {
      min-width: 50px;
      height: 50px;
      border-radius: 50%;
      max-width: 50px;
    }
  }
}
.stripe {
  &__card-icon {
    object-fit: contain;
  }
}
.camera-icon {
  position: absolute;
  z-index: 10;
  bottom: -4px;
  right: 0px;
}
// .custom-checkbox {
//   .container {
//     cursor: pointer;
//   }

//   .container input {
//     display: none;
//   }

//   .container svg {
//     overflow: visible;
//     height: 1em; /* Set the desired height */
//     width: 1em; /* Set the desired width */
//   }

//   .path {
//     fill: none;
//     stroke: #efb6b6;
//     stroke-width: 6;
//     stroke-linecap: round;
//     stroke-linejoin: round;
//     transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
//     stroke-dasharray: 241 9999999;
//     stroke-dashoffset: 0;
//   }

//   .container input:checked ~ svg .path {
//     stroke-dasharray: 70.5096664428711 9999999;
//     stroke-dashoffset: -262.2723388671875;
//   }
// }
.custom-checkbox {
  .container {
    cursor: pointer;
  }

  .container input {
    display: none;
  }

  .container svg {
    overflow: visible;
    height: 1em; /* Set the desired height */
    width: 1em; /* Set the desired width */
    margin-right: 0.5em;
  }

  .path {
    fill: none;
    stroke: grey;
    stroke-width: 5;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
    stroke-dasharray: 241 9999999;
    stroke-dashoffset: 0;
  }

  .container input:checked ~ svg .path {
    stroke: #4caf50; /* Change to your desired color, e.g., green */
    stroke-dasharray: 70.5096664428711 9999999;
    stroke-dashoffset: -262.2723388671875;
    stroke-width: 8;
  }
}
.signature-canvas {
  border: 1px solid black;
  width: 100;
  height: 500px;
}

.profile-progress {
  height: 10px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.main-logo {
  font-family: "Syncopate", sans-serif !important;
  font-size: 25px;
  &__t {
    margin-top: 7.4px !important;
    margin-right: -2px !important;
  }
  p {
    letter-spacing: -2px !important;
  }
  sup {
    font-size: 6px !important;
    // left: -1px;
    top: 8px;
    letter-spacing: 0px !important;
  }
}
