.main-header {
  height: 64px;
  background: #ffffff;

  &__heading {
    font-family: "Syncopate", sans-serif !important;
    font-weight: 700;
    font-size: 18px;
  }
  &__description {
    font-family: "Syncopate", sans-serif !important;
    font-weight: 400;
    font-size: 10px;
  }
  @media (max-width: 575.99px) {
    &__heading {
      font-size: 14px !important;
    }
    &__description {
      font-size: 8px !important;
    }
  }

  a {
    color: inherit;
    text-decoration: none !important;
  }
  a:hover {
    text-decoration: none;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
  }

  &__logo {
    // width: 150px;
    // height: 100px;
  }

  &__right {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 0px !important;
    font-size: 14px;
    white-space: nowrap !important;
    @media (min-width: 768px) and (max-width: 992px) {
      font-size: 12px !important;
    }
  }

  &__item {
    margin-left: 20px !important;
    cursor: pointer;
    position: relative;

    // &:hover::after{
    //     content:'';
    //     position: absolute;
    //     width: 100%;
    //     height: 0.175rem;
    //     background: #ffc3c3;
    //     left:0;
    //     bottom: 0;
    //     scale: (0,1);
    //     transition: transform 0.3 ease;
    // }

    &:hover {
      // transition: all 0.3s ease;
      border-bottom: 2px solid #d8953e;
    }
  }

  &__profile {
    &:hover {
      border: none !important;
    }
  }

  &__search {
    width: 15px;
    &:hover {
      border: none !important;
    }
  }

  &__list-icon {
    border: 1px solid #0000006e !important;
    border-radius: 2px;
    &:hover {
      border: 1px solid #0000006e !important ;
    }
  }

  &__btn {
    box-shadow: 1px 1px 4px 0px #00000040 !important;
    border: none !important;
    background: #d8953e;
    &:hover {
      border: none !important;
    }
    &:active,
    &:focus {
      background: #d8953e !important;
      border: none;
    }
  }

  //sidebar
  .sidebar {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    // transition:  all 400ms ease-out 0ms;
    transition: width 400ms ease;

    padding: 0px;

    &.open {
      width: 250px;
      padding: 20px !important;
      @media (max-width: 575.99px) {
        width: 100% !important;
      }
    }
  }

  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #22222299;
    z-index: 1;
  }

  @media (min-width: 992px) and (max-width: 1100px) {
    &__item {
      margin-left: 15px !important;
    }
  }

  @media (max-width: 991.99px) {
    &__item {
      margin-left: 0px !important;
      margin-top: 10px;

      &:hover {
        // transition: all 0.3s ease;
        border-bottom: 2px solid #d8953e;
      }
    }
  }
}

// ---------
.fBold.active {
  font-weight: bold;
}

// ------------
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}
