.auth {
  &-page {
    min-height: calc(100vh - 64px) !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  min-width: 100% !important;

  &__logo {
    // width: 227px;
    // height: 151px;
    width: 35%;
  }

  &__label {
    font-family: "Inter", sans-serif !important ;

    font-size: 14px !important;
    font-weight: 400 !important;

    letter-spacing: 0em !important;
    text-align: left !important;
  }
  &__card {
    padding: 30px;
    border-radius: 6px;

    box-shadow: 0px 2px 4px 0px #151d280a;
    border: 1px solid #dee4ed;
    background: #ffffff;
    width: 100%;

    .form-control {
      box-shadow: 0px 1px 2px 0px #151d2808;
      border: 1px solid #dee4ed;
      border-radius: 6px;
      color: #000000;
      font-family: "Inter", sans-serif;

      &:focus {
        outline: none !important;
      }
      &::placeholder {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
        color: #788ba5;
      }
    }
  }

  &__btn {
    border-radius: 6px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #4938c3;

    &:focus {
      box-shadow: none;
    }
  }
  &__link:hover {
    text-decoration: none;
    color: #117df9;
  }

  &__icon {
    top: 10px !important;
    right: 10px !important;
    cursor: pointer !important;
    position: absolute !important;
    color: #788ba5 !important;
  }
  &__icon-field {
    padding-right: 35px;
  }

  @media (max-width: 575.99px) {
    &__card {
      padding: 20px;
    }
  }
}
.accordion-button:not(.collapsed) {
  background-color: #efb6b6;
}
.text-responsive {
  font-size: 16px !important;
  @media (max-width: 575.99px) {
    p {
      font-size: 8px !important;
    }
  }
}
.signature-responsive {
  width: 90%;
  height: 500px;
  border: 1px solid grey;
  @media (max-width: 775.99px) {
    width: 90%;
    height: 300px;
    border: 1px solid grey;
  }
}
.text-responsive {
  @media (max-width: 575.99px) {
    p {
      font-size: 12px !important;
    }
  }
}
