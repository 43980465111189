.search {
  font-family: "Inter", sans-serif;

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .form-control {
    border: Mixed solid #cecece;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    &:focus {
      border: Mixed solid #cecece !important;
      box-shadow: none !important;
      outline: none !important;
      border-color: #cecece !important;
    }
  }

  .btn {
    // &:hover,
    &:focus,
    &:active {
      background: #d8953e !important;
      box-shadow: none !important;
    }
  }
}
