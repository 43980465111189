//Testimonials section

.testimonials {
  font-family: "Inter", sans-serif;
  &__heading {
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
  }

  &__card {
    border: 1px solid #000000;
    box-shadow: 0px 2px 4px 0px #00000040;
    border-radius: 8px;
    padding: 15px;

    &-title {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0em;
      text-align: left;
    }
    &-rating {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;
    }

    &-description {
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  &__bottom {
    img {
      width: auto;
      height: 18.75px;
    }
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    width: fit-content;
  }

  @media (max-width: 767.99px) {
    &__heading {
      font-size: 35px;
    }

    &__bottom {
      font-size: 15px;
    }

    &__card {
      padding: 13px;

      &-title {
        font-size: 17px;
      }
      &-rating {
        font-size: 17px;
      }

      &-description {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 575.99px) {
    &__heading {
      font-size: 30px;
    }

    &__bottom {
      font-size: 14px;
    }

    &__card {
      padding: 10px;

      &-title {
        font-size: 16px;
      }
      &-rating {
        font-size: 16px;
      }

      &-description {
        font-size: 13px;
      }
    }
  }
}

//Beauty Section
.beauty {
  font-family: "Inter", sans-serif;

  &__title {
    font-size: 48px;
    font-weight: 800;
    letter-spacing: 0em;
    text-align: left;
    text-transform: capitalize;
    line-height: 66px;
  }

  &__description {
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
  }

  @media (max-width: 1199.99px) {
    &__title {
      font-size: 40px;
      line-height: 50px;
    }
    &__description {
      font-size: 20px;
      line-height: 28px;
    }
  }

  @media (max-width: 767.99px) {
    &__title {
      font-size: 30px;
      line-height: 40px;
    }
    &__description {
      font-size: 16px;
      line-height: 24px;
    }

    img {
      height: 400px !important;
      width: 100% !important;
      object-fit: contain;
    }
  }

  @media (max-width: 567.99px) {
    &__title {
      font-size: 25px;
      line-height: 35px;
    }
    &__description {
      font-size: 14px;
      line-height: 22px;
    }
    img {
      height: 350px !important;
    }
  }
}

//Show Now

.show-now {
  font-family: "Inter", sans-serif;

  &__title {
    font-size: 45px;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    line-height: 66px;
  }
  &__btn {
    box-shadow: 0px 2px 4px 0px #00000040;
    background: #ffffff;
    border-radius: 12px;
    border: none !important;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    &:hover {
      background: #ffffff;
    }
  }

  @media (max-width: 1199.99px) {
    &__title {
      font-size: 40px;
      line-height: 50px;
    }
    &__btn {
      font-size: 22px;
    }
  }

  @media (max-width: 767.99px) {
    &__title {
      font-size: 35px;
      line-height: 40px;
    }
    &__btn {
      font-size: 18px;
    }
  }

  @media (max-width: 567.99px) {
    &__title {
      font-size: 30px;
      line-height: 35px;
    }
    &__btn {
      font-size: 16px;
    }
  }
}

// Product Delivery

.product-delivery {
  font-family: "Inter", sans-serif;

  &__title {
    font-size: 48px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
  }

  &__icon {
    width: 93px;
    height: 93px;
    border-radius: 24px;
    img {
      width: 80% !important;
    }
  }

  &__heading {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
    color: #2f2c4a;
  }
  &__description {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: capitalize;
  }

  @media (max-width: 1199.99px) {
    &__title {
      font-size: 40px;
    }

    &__icon {
      width: 91px;
      height: 91px;
    }

    &__heading {
      font-size: 30px;
    }
    &__description {
      font-size: 18px;
    }
  }

  @media (max-width: 767.99px) {
    &__title {
      font-size: 30px;
    }

    &__icon {
      width: 85px;
      height: 85px;
    }

    &__heading {
      font-size: 25px;
    }
    &__description {
      font-size: 16px;
    }
  }

  @media (max-width: 567.99px) {
  }
}

//Hero Section
.hero-main {
  overflow: hidden;
}

.hero-section {
  font-family: "Inter", sans-serif;

  overflow-x: hidden;
  background: linear-gradient(
    179.37deg,
    #faddbf -13.78%,
    rgba(255, 255, 255, 0) 134.73%
  );

  border-radius: 0px 366.5px 366.5px 0px !important;
  height: 733px;
  &__right-mobile {
    background-image: url("../img/herocircle.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__right {
    background-image: url("../img/herocircle.png");
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;

    // img {
    //   position: absolute;
    //   object-fit: contain;
    //   right: 200px !important;
    //   bottom: 0px;
    // }
  }

  &__img {
    position: absolute;
    object-fit: contain;
    // right: 200px !important;
    right: 148px !important;
    bottom: 0px;
    z-index: 3;
  }

  &__img-circle {
    position: absolute;
    object-fit: contain;
    right: 14px !important;
    bottom: 0px;
    z-index: 2;
    bottom: 55px;
  }

  &__title {
    font-size: 56px;
    font-weight: 800;
    line-height: 66px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: capitalize;
  }
  &__description {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: capitalize;
  }
  &__carousel-img {
    height: 80vh !important;
    object-fit: cover !important;
  }
  &__carousel-title {
    font-size: 28px;
    font-weight: 700;
    text-align: left;
  }
  &__carousel-Caption {
    width: 25% !important;
    @media (max-width: 1480.99px) {
      width: 40% !important;
    }
    @media (max-width: 991.99px) {
      width: 70% !important;
    }
    @media (max-width: 786.99px) {
      width: 80% !important;
    }
  }
  &__carousel-description {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }
  @media (max-width: 1199.99px) {
    height: 500px;
    &__img-circle {
      width: 544px;
      height: 521px;
      bottom: -10px;
      right: -5px !important;
    }
    &__img {
      width: 334px;
      height: 589px;
      right: 125px !important;
      bottom: -31px;
    }

    &__title {
      font-size: 50px;
      font-weight: 800;
      line-height: 60px;
    }
    &__description {
      font-size: 22px;
      line-height: 30px;
    }
    &__carousel-title {
      font-size: 26px;
      font-weight: 700;
      text-align: left;
    }
    &__carousel-description {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }
    // height: 650px;
    // &__img-circle {
    //   width: 544px;
    //   height: 521px;
    // }
    // &__img {
    //   width: 334px;
    //   height: 589px;
    // }
  }

  @media (max-width: 991.99px) {
    height: 400px;
    &__img-circle {
      width: 444px;
      height: 421px;
      bottom: -10px;
      right: -13px !important;
    }
    &__img {
      width: 268px;
      height: 489px;
      right: 100px !important;
      bottom: -31px;
    }
    &__title {
      font-size: 35px;
      line-height: 43px;
    }
    &__description {
      font-size: 19px;
      line-height: 27px;
    }
    &__carousel-title {
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }
    &__carousel-description {
      font-size: 12px;
      font-weight: 500;
      text-align: left;
    }
  }

  @media (max-width: 767.99px) {
    height: auto !important;

    border-radius: 0px !important;
    &__img-circle {
      display: none !important;
    }
    &__img {
      display: none !important;
    }
    &__right-mobile {
      img {
        width: 50% !important;
      }
    }
    &__title {
      font-size: 30px;
      line-height: 38px;
    }
    &__description {
      font-size: 17px;
      line-height: 25px;
    }
    &__carousel-title {
      font-size: 20px;
      font-weight: 700;
      text-align: left;
    }
    &__carousel-description {
      font-size: 10px;
      font-weight: 500;
      text-align: left;
    }
  }

  @media (max-width: 575.99px) {
    &__title {
      font-size: 25px;
      line-height: 33px;
    }
    &__description {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
